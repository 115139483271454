import { useEffect, useRef, useState } from 'react'
import '../App.css'
import Dropdown from '../components/Dropdown'
import Header from '../components/Header'
import {
  BackgroundType,
  PostSize,
  PostStyle,
  backgrounds,
  copyImageToClipboard,
  exportImageAsJPEG,
  exportImageAsPNG,
  getDynamicFontSize,
} from '../helpers'

type Background = {
  type: BackgroundType
  value: string
  title: string
}

function App() {
  const imageRef = useRef(null)
  const [postText, setPostText] = useState(
    'Write more captivating posts with Vyyme! 💥'
  )
  const [username, setUsername] = useState('Johnny Bones')
  const [userTitle, setUserTitle] = useState('Investor | Entrepreneur 📈')
  const [fontSize, setFontSize] = useState('text-3xl')
  const [profilePicture, setProfilePicture] = useState<File | null>(null)
  const [postSize, setPostSize] = useState<PostSize>(PostSize.wide)
  const [background, setBackground] = useState<Background>(backgrounds[0])
  const [backgroundsOpen, setBackgroundsOpen] = useState(false)
  const [downloadOpen, setDownloadOpen] = useState(false)
  const [showProfileForm, setShowProfileForm] = useState(true)
  const [showPostForm, setShowPostForm] = useState(true)
  const [postStyle, setPostStyle] = useState<PostStyle>(PostStyle.card)

  useEffect(() => {
    setFontSize(getDynamicFontSize(postText.length, postStyle))
  }, [postText])

  return (
    <div className="flex flex-col h-full min-h-screen items-center">
      <Header />
      <div className="main flex items-center flex-col max-w-[900px] h-full pb-20">
        <div className="flex flex-col justify-center items-center">
          <div className="flex flex-wrap justify-center w-full">
            <div className="button-group">
              <div
                onClick={() => setPostStyle(PostStyle.card)}
                className={`${
                  postStyle === PostStyle.card ? 'selected' : ''
                } button-container`}
              >
                <img src="assets/card-style.svg" alt="card" className="w-10" />
                <button>Card</button>
              </div>
              <div
                onClick={() => setPostStyle(PostStyle.basic)}
                className={`${
                  postStyle === PostStyle.basic ? 'selected' : ''
                } button-container`}
              >
                <img
                  src="assets/basic-style.svg"
                  alt="basic"
                  className="w-10"
                />
                <button>Basic</button>
              </div>
            </div>
            <div className="button-group">
              <div
                onClick={() => setPostSize(PostSize.wide)}
                className={`${
                  postSize === PostSize.wide ? 'selected' : ''
                } button-container`}
              >
                <img src="assets/wide.svg" alt="card" className="w-12 h-10" />
                <button>Wide</button>
              </div>
              <div
                onClick={() => setPostSize(PostSize.square)}
                className={`${
                  postSize === PostSize.square ? 'selected' : ''
                } button-container`}
              >
                <img src="assets/square.svg" alt="card" className="w-8 h-10" />
                <button>Square</button>
              </div>
              <div
                onClick={() => setPostSize(PostSize.portrait)}
                className={`${
                  postSize === PostSize.portrait ? 'selected' : ''
                } button-container`}
              >
                <img
                  src="assets/portrait.svg"
                  alt="card"
                  className="w-8 h-10"
                />
                <button>Portrait</button>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="max-w-[90vw] overflow-scroll">
              <div
                ref={imageRef}
                className={`content ${postSize} ${background.value}`}
              >
                <div className={`${postStyle}`}>
                  <div className="post-header">
                    <div className="profile-picture">
                      {profilePicture && (
                        <img
                          src={URL.createObjectURL(profilePicture)}
                          alt="profile"
                        />
                      )}
                    </div>
                    <div className="flex flex-col justify-between">
                      <p className="name">
                        {username}
                        <img
                          src="assets/verification.svg"
                          alt="verified"
                          className="mx-2 mt-1 w-4 h-4"
                        />
                      </p>
                      <p className="user-title">{userTitle}</p>
                    </div>
                  </div>
                  <div className="post-body">
                    <p className={`${fontSize}`}>{postText}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end w-full mt-5 gap-3">
              <button
                onClick={() => copyImageToClipboard(imageRef)}
                className="text-input text-black bg-white w-40"
              >
                Copy image
              </button>
              <Dropdown
                open={downloadOpen}
                onClick={() => setDownloadOpen(!downloadOpen)}
                value="Download"
                accent
                compact
              >
                <div
                  onClick={() => {
                    exportImageAsJPEG(imageRef)
                    setDownloadOpen(false)
                  }}
                  className="option"
                >
                  JPEG
                </div>
                <div
                  onClick={() => {
                    exportImageAsPNG(imageRef)
                    setDownloadOpen(false)
                  }}
                  className="option"
                >
                  PNG
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full mt-10 gap-10 max-w-[800px]">
          <div>
            <div
              onClick={() => setShowProfileForm(!showProfileForm)}
              className="flex items-center gap-4 px-4 cursor-pointer"
            >
              <i className={`arrow ${showProfileForm ? 'up' : 'down'}`} />
              <h2 className="font-bold text-2xl">Profile</h2>
            </div>
            <hr className="border-slate-300 my-2" />
            <section
              className={`${
                showProfileForm ? 'block' : 'hidden'
              } transition-all ease-in-out duration-300`}
            >
              <div className="w-full flex flex-wrap justify-center mt-5">
                <div className="flex flex-col">
                  <label className="input-label" htmlFor="username">
                    Username
                  </label>
                  <input
                    className="text-input"
                    placeholder="Username"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="input-label" htmlFor="userTitle">
                    User title
                  </label>
                  <input
                    className="text-input"
                    placeholder="User title"
                    onChange={(e) => setUserTitle(e.target.value)}
                  />
                </div>
                <div className="flex flex-col my-8 justify-center items-center">
                  <label className="input-label" htmlFor="profilePicture">
                    Profile picture
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) =>
                      e.target.files && setProfilePicture(e.target.files[0])
                    }
                  />
                </div>
                <div></div>
              </div>
            </section>
          </div>
          <div>
            <div
              onClick={() => {
                setShowPostForm(!showPostForm)
              }}
              className="flex items-center gap-4 px-4 cursor-pointer"
            >
              <i className={`arrow ${showPostForm ? 'up' : 'down'}`} />
              <h2 className="font-bold text-2xl">Post</h2>
            </div>
            <hr className="border-slate-300 my-2" />
            <section className={`${showPostForm ? 'block' : 'hidden'}`}>
              <div className="w-full flex flex-wrap justify-center items-start mt-5">
                <div className="flex flex-col">
                  <label className="input-label" htmlFor="postText">
                    Post content
                  </label>
                  <textarea
                    className="text-input h-52 resize-none"
                    placeholder="Post text"
                    onChange={(e) => setPostText(e.target.value)}
                  />
                </div>
                <div className="flex flex-col justify-between">
                  <div className="flex flex-col">
                    <label className="input-label" htmlFor="background">
                      Background color
                    </label>
                    <Dropdown
                      open={backgroundsOpen}
                      onClick={() => setBackgroundsOpen(!backgroundsOpen)}
                      value={background.title}
                    >
                      {backgrounds.map((background, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            setBackground(background)
                            setBackgroundsOpen(false)
                          }}
                          className="option"
                        >
                          {background.title}
                        </div>
                      ))}
                    </Dropdown>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
