interface DropdownProps {
  onClick: () => void
  value: string
  children?: React.ReactNode
  accent?: boolean
  open?: boolean
  compact?: boolean
}

export default function Dropdown({
  onClick,
  value,
  children,
  accent,
  open = false,
  compact = false,
}: DropdownProps) {
  return (
    <div
      onClick={onClick}
      className={`dropdown text-input ${compact ? 'w-40' : ''}`}
    >
      <input
        className={`text-box ${accent && 'accent'}`}
        type="text"
        placeholder="Select on"
        readOnly
        value={value}
      />
      <div className={`options ${open ? 'block' : 'hidden'}`}>{children}</div>
    </div>
  )
}
