import { Link } from 'react-router-dom'

const Header = () => {
  const paths = [
    {
      label: 'Home',
      path: '/',
    },
    {
      label: 'Create',
      path: '/create',
    },
  ]
  return (
    <div className="w-full items-center justify-center flex p-5">
      <img
        src="assets/logo.png"
        alt="logo"
        className="hidden sm:block w-28 md:w-40 absolute left-5 cursor-pointer"
        onClick={() => (window.location.href = '/')}
      />
      <div className="w-full flex justify-center gap-16">
        {paths.map((path) => {
          //const isSelected = window.location.pathname === path.path
          return (
            <Link key={path.label} to={path.path}>
              {path.label}
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default Header
