import { toBlob } from 'html-to-image'

export const getDynamicFontSize = (
  length: number,
  postStyle: PostStyle
): string => {
  if (postStyle === PostStyle.basic) {
    if (length < 30) {
      return 'enormous'
    } else if (length < 100) {
      return 'huge'
    } else if (length < 180) {
      return 'large'
    } else if (length < 400) {
      return 'medium'
    } else {
      return 'small'
    }
  } else {
    if (length < 30) {
      return 'enormous'
    } else if (length < 70) {
      return 'huge'
    } else if (length < 130) {
      return 'large'
    } else if (length < 180) {
      return 'medium'
    } else {
      return 'small'
    }
  }
}

export enum BackgroundType {
  color = 'color',
  gradient = 'gradient',
}

export enum PostSize {
  wide = 'wide',
  square = 'square',
  portrait = 'portrait',
}

export enum PostStyle {
  basic = 'basic',
  card = 'card',
}

export const backgrounds = [
  {
    type: BackgroundType.color,
    value: 'bg-black',
    title: 'Black',
  },
  {
    type: BackgroundType.color,
    value: 'bg-red-500',
    title: 'Red',
  },
  {
    type: BackgroundType.color,
    value: 'bg-green-500',
    title: 'Green',
  },
  {
    type: BackgroundType.color,
    value: 'bg-blue-500',
    title: 'Blue',
  },
  {
    type: BackgroundType.color,
    value: 'bg-yellow-500',
    title: 'Yellow',
  },
  {
    type: BackgroundType.color,
    value: 'bg-pink-500',
    title: 'Pink',
  },
  {
    type: BackgroundType.gradient,
    value: 'bg-gradient-to-r from-red-500 to-yellow-500',
    title: 'Red to Yellow',
  },
  {
    type: BackgroundType.gradient,
    value: 'bg-gradient-to-r from-slate-500 to-cyan-500',
    title: 'Space',
  },
]

export const exportImageAsPNG = (
  ref: React.MutableRefObject<HTMLDivElement>
) => {
  toBlob(ref.current, {
    pixelRatio: 2,
  }).then(function (blob) {
    if (!blob) return
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'image.png'
    link.click()
  })

}

export const exportImageAsJPEG = (
  ref: React.MutableRefObject<HTMLDivElement>
) => {
  
  if (!ref.current) return
  toBlob(ref.current, {
    pixelRatio: 2,
  }).then(function (blob) {
    if (!blob) return
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'image.jpeg'
    link.click()
  })
}

export const copyImageToClipboard = (
  ref: React.MutableRefObject<HTMLDivElement>
) => {
  if (!ref.current) return
  if (!('ClipboardItem' in window)) {
    return alert(
      'Your browser doesn\'t support copying images into the clipboard.'
      + ' If you use Firefox you can enable it'
      + ' by setting dom.events.asyncClipboard.clipboardItem to true.'
    )
  }
  toBlob(ref.current, {
    pixelRatio: 2,
  }).then(function (blob) {
    if (!blob) return
    navigator.clipboard.write([
      new ClipboardItem({
        [blob.type]: blob,
      }),
    ])
  })
}
